import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import StoreContext from "../../../ContextApi";
import whitepaper from "../BlogImages/whitepaper.png";
import { Button, Modal } from "antd";
import "./BlogContent.css";
import BlogThreeStyle from "./BlogThree.module.css";
import handImg from "../BlogImages/Blog4Images/Blog.png";
import BannerImg from "../BlogImages/Blog7image/block7bannerimage.png";
import BlogsevenContentImg from "../BlogImages/Blog7image/blog7contentImage.png";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


export default function BlogSeven({ copyText }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "0";
  const [copySuccess, setCopySuccess] = React.useState("");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const textArea = document.querySelector("textarea");
    textArea.select();
    document.execCommand("copy");
    textArea.blur();
    setCopySuccess("Copied!");
  };

  const changeTab = (tab) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };
  let ContextData = useContext(StoreContext);
  let data = ContextData.BlogData;
  // console.log(data.metaDescription, "raza=====>");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [searchParams]);
  // console.log(window.location.pathname.slice(1), "link raza");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <div>
          <section className="banner_container">
            <div id="banner_17_image"></div>
            <div class="overlay"></div>
            <div class="banner_text__1">
              The Impacts of Strategic Budgeting Methods on Business

              <div style={{ display: "flex", gap: "45px", position: "absolute", bottom: "-180px", right: '60px', color: "white", }} className="author_none">
                
                <div style={{ display: "flex", gap: '3px' }}>
                  <CalendarMonthIcon style={{ position: "relative", top: '4px' }} />
                  <p style={{ textAlign: 'center', fontSize: '1.4rem', fontWeight: "bold" }}>Updated on: 19/7/24</p>
                </div>
              </div>
            </div>
          </section>

          <Modal
            // title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div>
              <input type="text" value={copyText} readOnly />
              {/* Bind our handler function to the onClick button property */}
              <button>
                <span>{isCopied ? "Copied!" : "Copy"}</span>
              </button>
            </div>
          </Modal>
          <br />
          <br />
          <br />
          <div style={{ margin: "0px 30px", marginTop: "-50px" }}>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3 ">
                <div class="social-sharing">
                  <div class="heading-container">
                    <h2
                      style={{
                        textAlign: "center",
                      }}
                    >
                      SHARE
                    </h2>
                    <hr />
                    <p className="mb-1 table_my_res">
                      Share across your favourite social media:
                    </p>

                    <div id="LinkIconMain">
                      <div id="BoxPointer" onClick={showModal}>
                        <i class="fab fa-facebook-f"></i>
                      </div>
                      <div id="BoxPointer" onClick={showModal}>
                        <i class="fab fa-twitter"></i>
                      </div>
                      <div id="BoxPointer" onClick={showModal}>
                        <i class="material-icons">email</i>
                      </div>
                      <div id="BoxPointer" onClick={showModal}>
                        <i class="fab fa-linkedin-in"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 blog-main m-auto">
                <div className="blog-post">
                  {/* <div className="main_text_center">
                    <h1 className="table_my_res" style={{ fontSize: "3rem", background: "#81AFD9", padding: '3px 3px', position: "relative", top: "-65px" }}>
                      The Impacts of Strategic Budgeting Methods on Business
                    </h1>
                    <p></p>
                    <div style={{ display: "flex", gap: "45px", position: "absolute", bottom: "-105px", right: '60px', color: "white" }}>
                      <div>
                        <p style={{ textAlign: 'center', fontSize: '1.4rem', fontWeight: "bold" }}>Author Azfar Saleem</p>
                      </div>
                      <div style={{ display: "flex", gap: '3px' }}>
                        <CalendarMonthIcon style={{ position: "relative", top: '4px' }} />
                        <p style={{ textAlign: 'center', fontSize: '1.4rem', fontWeight: "bold" }}>updated on 22/12/23</p>
                      </div>
                    </div>
                  </div> */}

                  <div style={{ padding: "30px 0px" }}>
                    <p className="w3-text-grey table_Justify" style={{ fontWeight: "bold", marginTop: "-30px"}}>
                      If you envision a positive cash flow for your business, 
                      strategic budgeting methods are the ideal techniques to undertake. These techniques are tailored to your business strategies. Bringing systems that are valuable components of gaining increased profitability and sensible investments.
                    </p>
                    <p className="w3-text-grey table_Justify">
                      A budget is an outline of the expected costs and income
                      beared or earned by business(es). Organizations
                      occasionally change their budgets according to
                      performance, too. Laying out financial plans for future
                      expenses or generating revenue allows ventures to create
                      effective budgets that all internal stakeholders are instructed to follow.
                    </p>
                    <p className="w3-text-grey table_Justify">
                      Outlining Strategic budgeting methods allows firms to
                      uncover suitable paths leading to business expansion. When an
                      outfit has an effectives budget in place, managers can
                      ensure there is adequate income to relieve anticipated
                      expenses or abrupt costs. A roadmap for financial futures
                      is available when creating budgets. Whether they're aiming to save
                      for a big purchase or simply looking into gaining control
                      of spending<a href="#refone"><sup>(1)</sup></a>, mastering the art of budgeting is
                      essential.
                    </p>
                    <p className="w3-text-grey table_Justify">
                      Effectively planning and implementing strategic budgeting
                      methods for business enable democratic communicative
                      action among corresponding business departments. Reducing
                      chances of a scathing corresponding financial system. Once
                      a schematic financial budget is in place, each department
                      within a company will plan their expenses accordingly.
                      Creating a strong foundation of financial security.
                    </p>
                    <p className="w3-text-grey table_Justify">
                      Without strategic budgeting methods for financial
                      management, businesses suffer far-reaching constraints.
                      A Shortage of budgets can lead to uninformed financial
                      decisions including:
                    </p>
                    <ol className="w3-text-grey"><h3>1. Imminent Failure,</h3>
                    
                    <h3> 2. A lack of accountability,</h3> 
                    
                   <h3>  3. Impractical expectations,{" "}</h3>
                    
                      <h3>4. Cash flow issues.{" "}</h3>
                    </ol>
                    <p className="w3-text-grey table_Justify" style={{ padding: "5px 0px" }}>
                      The aforementioned circumstances are entirely avoidable if a well-planned budget
                      is in order. Analyzing available capital, targeted
                      revenue, and predicting future expenses allows businesses
                      to make informed financial decisions.
                    </p>
                    <div className={BlogThreeStyle.main___conatiner1}>
                      <h2 className="w3-text-grey table_my_res" id="heading1">
                        The Art of Expense Management
                      </h2>
                      <br></br>
                      <p className="w3-text-grey table_Justify">
                        Achieving financial goals heavily depends on tracking,
                        budgeting and controlling business expenses. Regardless
                        of a company’s or any institution’s financial situation,
                        the art of mapping expenses on operations yields numerous advantages.
                        Expense management does not particularly require assistance from experienced
                        professionals. Any member of your organization can become proficient in the skill
                        by accurately analyzing regular expenses, forecasting
                        costs, spending monetary resources wisely,
                        and reaching their financial goals efficiently.
                      </p>
                      <p className="w3-text-grey table_Justify">
                        Being cleverly mindful about corporate
                        expenses is imperative, otherwise businesses can unavoidably   face
                        significant problems.
                      </p>
                    </div>
                    <div className={BlogThreeStyle.main___conatiner1}>
                      <h3 className="w3-text-grey table_my_res" id="heading2">
                        Ignoring Strategic Budgeting Methods - The Pitfalls
                      </h3>
                      <p className="w3-text-grey table_Justify">
                        1. Huge credit problems: Without building  robust budget to pay off suppliers, distributors of your raw
                        materials or products, growing discomfort will arise in working with you again. These effects
                        will spill on to your alternative suppliers or
                        distributors of vital material.
                      </p>
                      <p className="w3-text-grey table_Justify">
                        2. Excessive debt: Uncontrolled spending will lead
                        to the strain of fulfilling your financial obligations. Making your business productively inefficient in producing profits.
                        If burdens of previous charges have not been diminished.
                      </p>
                      <p className="w3-text-grey table_Justify">
                        3. Financial instability: Fluctuations emerge in
                        finances when there is mismanagement of expenditures.
                      </p>
                    </div>
                    <div className={BlogThreeStyle.img_container}>
                      <img src={BlogsevenContentImg} alt="logo" />
                    </div>
                    <div className={BlogThreeStyle.main___conatiner2}>
                      <h3 className="w3-text-grey table_my_res" id="heading3">
                        Premium Strategic Budgeting Methods
                      </h3>
                      <p className="w3-text-grey table_Justify">
                        If your business's strategic budgeting methods are well-executed, they will trigger a prolific surge in
                        collaborative performance. When they are meticulously
                        implemented, skillfully crafted budgets bring major gains
                        for all types of businesses:
                      </p>
                      <ul className="w3-text-grey table_Justify">
                        1. Objectives planned for the future will be
                        recorded to determine which business operations are to
                        be prioritized.
                      </ul>
                      <ul className="w3-text-grey table_Justify">
                        2. Estimates of expenses and income give a comprehensive
                        understanding of proceeds to spend on
                        expenditures.
                      </ul>
                      <ul className="w3-text-grey table_Justify">
                        3. Being flexible enough to change budgets according to
                        realities allow for appropriate adjustments.
                      </ul>
                      <p className="w3-text-grey table_Justify">
                        Excelling in the art of expense management for business
                        brings an impeccable touch to your organisational
                        operations. By anticipating
                        essential expenses and projecting income appropriately, realize the
                        rewards described earlier. Let's discuss ideal strategic
                        budgeting methods for business below:
                      </p>

                      <ul
                        className="w3-text-grey table_Justify"
                        style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                        id="heading4"
                      >
                        1. Budgeting from Scratch:
                      </ul>
                      <ul className="w3-text-grey table_Justify">
                        Businesses that start off their operations or projects
                        by organising their net income, set a framework for
                        prescribing their business tactics. In case(s) of rising
                        costs and spending more in an upcoming year instead,
                        creating a new budget will allow businesses to control
                        their spending to make certain that funds are assigned
                        efficiently.
                      </ul>

                      <ul
                        id="heading5"
                        className="w3-text-grey"
                        style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                      >
                        2. Adaptive Budgeting:
                      </ul>
                      <ul className="table_Justify">
                        To dodge frugal financial outlay, adaptive budgeting is
                        commonly used according to a business’s circumstance(s).
                        For example, if your clothing boutique offers clothes
                        for winters, you can’t expect higher or equivalent sales
                        in summers. In short, you will need to make sure the
                        costs your income is unable to justify. With adaptive
                        budgeting you can earn the revenue your business needs
                        and leave a lasting impact on your business’ revenue,
                        its workers and customers.
                      </ul>

                      <ul
                        id="heading6"
                        className="w3-text-grey table_Justify"
                        style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                      >
                        3. Operational Budgeting:
                      </ul>
                      <ul className="table_Justify">
                        This budgeting approach can be applied to track which
                        activity’s related costs can be reduced to have more
                        finances to allocate elsewhere. For instance AI can
                        create specific financial reports, freeing up a worker's
                        time to focus on other responsibilities.
                      </ul>

                      <ul
                        id="heading7"
                        className="w3-text-grey table_Justify"
                        style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                      >
                        4. Exception Reporting:
                      </ul>
                      <ul className="table_Justify">
                        When a business’s spending is not in line with revenue
                        gains, challenges surface in reconciling such
                        differences. Gratefully, when accounting exception
                        reports detail verifiable income values and expenses
                        that fail to offset each other, the variance(s) can be
                        identified. Once created, these exceptions can be
                        studied to make them things of the past and defeat those
                        obstacles specifically.
                      </ul>
                      <p className="table_Justify">
                        Strategic budgeting methods are a welcome relief for
                        existing businesses or new companies. Budgeting strategies are
                        the key for securing profitability, assigning
                        accountability, setting realistic expectations and
                        enhancing cash flow.
                      </p>
                    
                      <br></br>
                      <cite><h4>References</h4></cite>
                      <br></br>
                      <ol>
                       <cite><li id="refone">Ganti, A. (2024). What is a budget? Plus 10 budgeting myths holding you back. [online] Investopedia. Available at: https://www.investopedia.com/terms/b/budget.asp [Accessed 27 Jul. 2024].</li></cite> 
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
                    
              <div className="hide_res col-sm-12 col-md-3 col-lg-3 blog-sidebar">
                {/* <div className={BlogThreeStyle.border__line7}></div> */}
                <h6 className="table_my_res" style={{ padding: "10px 0px", fontSize: "1.5rem" }}>
                  Table of Contents
                </h6>
                <div className={BlogThreeStyle.second__para_container}>
                  <div className={BlogThreeStyle.second__para_container1}>
                    <p>
                      <a href="#heading1">The Art of Expense Management</a>
                    </p>
                    <p>
                      <a href="#heading2">
                        Pitfalls of Ignoring Strategic Budgeting Methods
                      </a>
                    </p>
                    <p>
                      <a href="#heading3">
                        Premium Strategic Budgeting Methods
                      </a>
                    </p>
                    <p>
                      <a href="#heading4">1. Budgeting from Scratch:</a>
                    </p>
                    <p>
                      <a href="#heading5">2. Adaptive Budgeting:</a>
                    </p>
                    <p>
                      <a href="#heading6">3. Operational Budgeting:</a>
                    </p>
                    <p>
                      <a href="#heading7">4. Exception Reporting:</a>
                    </p>
                  </div>
                </div>
              </div>

              <div id="LinkIconMain">
                <div id="BoxPointer">
                  <i class="fab fa-facebook-f"></i>
                </div>
                <div id="BoxPointer">
                  <i class="fab fa-twitter"></i>
                </div>
                <div id="BoxPointer">
                  <i class="material-icons">email</i>
                </div>
                <div id="BoxPointer">
                  <i class="fab fa-linkedin-in"></i>
                </div>
              </div>
              <p className="text-center ">
                Share across your favourite social media!
              </p>
            </div>

            <hr />
            <div class="d-flex flex-wrap justify-content-center">
              <div
                class="card m-2 p-2 col-sm-3"
                style={{
                  background: "#81AFD9",
                  color: "white",
                  textAlign: "center",
                  borderRadius: "13px",
                }}
              >
                <div class="container">
                  <h4>
                    <b className="text-white">TecStik</b>
                  </h4>
                  <p>
                    Managing Physical Cash Payment Risks in Pakistan's Cash
                    Economy
                  </p>
                </div>
                <img
                  src={whitepaper}
                  alt="Avatar"
                  // style={{ width: "40vh", height: "50vh" }}

                  className="m-2"
                />
              </div>
              <div class="col-sm-4">
                <br />
                <div class="container">
                  <h4>
                    <b>Get your free white paper.</b>
                  </h4>
                  {/* <p>Architect & Engineer</p> */}
                  {/* <br /> */}
                  <div class="form-outline">
                    <input
                      type="email"
                      // id="typeNumber"
                      class="form-control mt-3"
                      style={{ background: "#F1F1F1" }}
                      placeholder="Your Email Address"
                    />
                    {/* <label class="form-label" for="typeNumber">
                        Email
                      </label> */}
                  </div>
                  <br />
                  <span type="button" class="btn btn-warning btn-rounded">
                    SUBMIT
                  </span>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
